<template>
  <div class="withdrawdeposit">
    <van-field class="withdrawdepositMoney" v-model="number" type="number" :placeholder="$t('WITHDRAWDEPOSIT')">
      <div slot="extra">{{$t('UNIT')}}</div>
    </van-field>
    <van-field class="withdrawdepositMoney" v-model="wdNumber" type="number" :placeholder="$t('PWDWDNUMBER')">
      <div slot="extra">{{$t('WDNUMBER')}}</div>
    </van-field>
    <div class="tips">
      <!-- กรุณาติดต่อเจ้าหน้าที่เพื่อขอรับรหัสถอนเงิน -->
    </div>
    <div class="btnRechargebox">
      <van-button color="#21A0FF" @click="sub" class="withdrawdepositbtn" type="primary" block>{{$t('LIJITIXIAN')}}</van-button>
      <van-image @click="getline" class="line" :src="lineImg"/>
      <!-- <van-button color="#ddd" class="cancel" type="primary" block>{{$t('CANCEL')}}</van-button> -->
    </div>
  </div>
</template>

<script>
import { withdrawMoney, getLineUrl } from '@/api/wallet'
import { Toast } from 'vant'
export default {
  name: 'withdrawdeposit',
  data () {
    return {
      number: null,
      wdNumber: null,
      lineImg: require('../../My/img/LineMessenger.png')
    }
  },
  methods: {
    async sub () {
      if (!this.number) {
        Toast(this.$t('WITHDRAWDEPOSITMONEYNULL'))
        return false
      }
      const { data } = await withdrawMoney({
        amountMoney: this.number,
        pwd: this.wdNumber
      })
      if (data.code === 200) {
        Toast(this.$t('SUBMITSUCCEED'))
        this.$router.push({
          name: 'wywithdrwdeposit'
        })
      }
      if (data.code === 500) {
        Toast(data.message)
      }
      console.log(data)
    },
    async getline () {
      try {
        // console.log(document.domain)
        const { data } = await getLineUrl({
          // url: document.domain
        })
        if (data.code === 200) {
          // console.log(data.data)
          location.href = data.data
        }
        // console.log(data)
      } catch (error) {
        console.log(error)
      }
    }
  }
}
</script>

<style scoped>
.tips {
  padding: 0 20px;
  font-size: 16px;
  color: red;
}
.withdrawdeposit{
  overflow: hidden;
  width: 320px;
  margin: 0 auto;
  margin-top: 20px;
  background: #fff;
}
.withdrawdepositMoney {
  margin: 20px 0;
  border: 2px solid #e1e1e1;
  font-size: 16px;
  border-radius: 5px;
}
.withdrawdepositbtn,
.cancel {
  float: left;
  width: 100px;
  height: 50px;
  font-size: 16px;
  border-radius: 8px;
}
.btnRechargebox {
  /* overflow: hidden; */
  display: flex;
  justify-content: space-around;
  margin: 30px 0;
}
.withdrawdepositbtn {
  /* margin-left: 100px; */
}
.line {
  width: 50px;
  height: 50px;
}
.cancel {
  margin-left: 10px;
}
</style>
